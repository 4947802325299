import * as React from "react"
import { Helmet } from "react-helmet"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import ContactForm from "../components/common/contact-form/contact-form"
import Layout from "../components/layout/Layout"

const ContactanosPage = () => {
    return (
        <Layout>
            <div className="faq-wrap">
                <Helmet>
                    <title>Mahpsa | Cotización</title>
                </Helmet>
                <Breadcrumb title={'Cotización'} />
                <div className={'section-wrapper'}>
                    <div className={'container pb-5'}>
                        <div className={'row justify-content-center'}>
                            <div className={'col-md-7'}>
                                <h4>Para darle una cotización por favor complete el formulario y le responderemos con todos los detalles</h4>
                            </div>
                        </div>
                        <div className={'row justify-content-center mt-5'}>
                            <div className={'col-md-6'}>
                                <ContactForm cotizarForm={true} dptoSelected={'consulta general'} origen={'Cotizacion page'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactanosPage
